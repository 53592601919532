var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"แก้ไขข้อมูลบัญชี"}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 w-full"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|numeric|max:10'),expression:"'required|numeric|max:10'"}],staticClass:"w-full mt-4",attrs:{"label":"หมายเลขบัญชี","placeholder":"กรุณาใส่หมายเลขบัญชี","name":"bankno"},model:{value:(_vm.new_data.bankno),callback:function ($$v) {_vm.$set(_vm.new_data, "bankno", $$v)},expression:"new_data.bankno"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('bankno')),expression:"errors.has('bankno')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("bankno")))]),_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|alpha_num|max:20'),expression:"'required|alpha_num|max:20'"}],staticClass:"w-full mt-4",attrs:{"label":"Username","placeholder":"กรุณาใส่ Username","name":"username"},model:{value:(_vm.new_data.username),callback:function ($$v) {_vm.$set(_vm.new_data, "username", $$v)},expression:"new_data.username"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('username')),expression:"errors.has('username')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("username")))]),_c('br'),_c('label',{staticClass:"vs-input--label"},[_vm._v("เลือกประเภทบัญชี")]),_c('vs-select',{staticClass:"w-full mt-4",model:{value:(_vm.new_data.bankreserve),callback:function ($$v) {_vm.$set(_vm.new_data, "bankreserve", $$v)},expression:"new_data.bankreserve"}},_vm._l((_vm.datagroups),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.value,"text":item.text}})}),1),(
          this.$route.params.bank === 'bank_kbank' ||
          this.$route.params.bank === 'bank_kbank_only'
        )?_c('vs-input',{staticClass:"w-full mt-4",attrs:{"type":"code","label":"code","placeholder":"กรุณาใส่ K-bank code","name":"code"},model:{value:(_vm.new_data.code),callback:function ($$v) {_vm.$set(_vm.new_data, "code", $$v)},expression:"new_data.code"}}):_vm._e(),(
          this.$route.params.bank === 'bank' ||
          this.$route.params.bank === 'bank_scb'
        )?_c('vs-input',{staticClass:"w-full mt-4",attrs:{"type":"deviceId","label":"deviceId","placeholder":"กรุณาใส่ Device-id","name":"deviceId"},model:{value:(_vm.new_data.deviceId),callback:function ($$v) {_vm.$set(_vm.new_data, "deviceId", $$v)},expression:"new_data.deviceId"}}):_vm._e()],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full mt-4",attrs:{"label":"ชื่อบัญชี","placeholder":"กรุณาใส่ชื่อบัญชี","name":"fullname"},model:{value:(_vm.new_data.fullname),callback:function ($$v) {_vm.$set(_vm.new_data, "fullname", $$v)},expression:"new_data.fullname"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('fullname')),expression:"errors.has('fullname')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("fullname")))]),_c('vs-input',{staticClass:"w-full mt-4",attrs:{"type":"password","label":"Password","placeholder":"กรุณาใส่ Password","name":"password"},model:{value:(_vm.new_data.password),callback:function ($$v) {_vm.$set(_vm.new_data, "password", $$v)},expression:"new_data.password"}}),(
          this.$route.params.bank === 'bank_kbank' ||
          this.$route.params.bank === 'bank_kbank_only' ||
          this.$route.params.bank === 'bank_scb' ||
          this.$route.params.bank === 'bank'
        )?_c('vs-input',{staticClass:"w-full mt-4",attrs:{"type":"pin","label":"pin","placeholder":"กรุณาใส่ pin","name":"pin"},model:{value:(_vm.new_data.pin),callback:function ($$v) {_vm.$set(_vm.new_data, "pin", $$v)},expression:"new_data.pin"}}):_vm._e()],1),_c('span',{staticClass:"text-danger text-sm mt-5 ml-6"},[_vm._v("เพื่อความปลอดภัย ระบบจะไม่แสดง Password , Pin , DeviceId เก่าของท่าน กรุณากรอกข้อมูลใหม่ที่ต้องการแก้ไขในช่องว่างได้เลย")])]),_c('div',{staticClass:"mt-8 flex flex-wrap items-center justify-end"},[_c('vs-button',{staticClass:"ml-4 mt-2",attrs:{"color":"success"},on:{"click":_vm.Save}},[_vm._v("ยืนยัน")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }